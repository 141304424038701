$(document).ready(function() {
    $("li.search_down_off").click(function(){
        $("p.search_down").slideToggle(200);
    });
    $('body').css('overflow','auto');
    $('.full-preloader').delay(500).fadeOut(1000);
});

$(".bs-helpcenter").click(function() {        
  $(".help-box").toggle();           
  $(".help-box").addClass("block");   
  $(".bs-helpcenter").toggleClass("active", 1000);      
  $(".submit-box").hide();     
  $(".bs-submit-sticky").removeClass("active");  
});

$(".bs-submit-sticky").click(function() {        
  $(".submit-box").toggle();           
  $(".submit-box").addClass("block");   
  $(".bs-submit-sticky").toggleClass("active", 1000);      
  $(".help-box").hide();   
  $(".bs-helpcenter").removeClass("active");  
});