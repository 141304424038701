'use strict';

angular
.module('bridestoryApp.tutorialApp')
.controller('tutorialSubheaderController', ['tutorialFactory', '$scope', '$q', '$rootScope', '$modal', 'resourceManager', 'userManager', 'vendormetaManager', 'resource.utility.lowestPriceService', 'resource.utility.availablePlanService', 'launchAppService', 'videoEssentialService', 'masterclassPromo', 'launchAppFactory', 'cookieServices', 'notificationService', 'AnalyticService', 'localStorageService', 'GeoLocationService', function(tutorialFactory, $scope, $q, $rootScope, $modal, resourceManager, userManager, vendormetaManager, lowestPriceService, availablePlanService, launchAppService, videoEssentialService, masterclassPromo, launchAppFactory, cookieServices, notificationService, AnalyticService, localStorageService, GeoLocationService) {

	var self = this,
		_validity = {
			contactNumber : true,
			profilePicture : true,
			addProject : true
		},
		_isPublished = false,
		_isExpiring = false,
		_isMonetized = false,
		_vendormeta = vendormetaManager.raw,
		_rate = lowestPriceService.rate,
		_currency = lowestPriceService.currency,
		_lowestPrice = lowestPriceService.lowestPrice,
		_expiringCity = [],
		_allCities = [],
		_transformedExpiringCity = '',
		_socmed = [],
		_showInstagramConnect = true,
		_oldInstagramConnected = false,
		_oldInstagram = '',
		_oldInstagramName = '',
		_accountManager = {},
		_bannerInstaClosed = false,
		_instagramVendor = false,
		_finishLoading = false,
		_pendingTransactions = [],
		_trialPackage = null,
		_origin = null,
		_isELigiblePrepaid = null,
		_activeMembership = null,
		_isInstagramExpired = false,
		_isInstagramReconnected = false,
		_quotation = {},
		_userLocation = null,
		_banners= null,
		_transactions = function(param){
			var defer = $q.defer();

			new resourceManager.skeleton({
			  API : [
			    '/transactions/pending',
			    {
			      params : param
			    }
			  ],
			  apiVersion : 'ms/bs-vendors/api/v1',
			  success : function(result) {
			    defer.resolve(result);
			  },
			  failed : function(error){
			    defer.reject(error);
			  }
			});

			return defer;
		},
		_banner = function(param){
			var defer = $q.defer();

			new resourceManager.skeleton({
			  API : [
			    '/banner?:params',
			    {
			      params : param
			    }
			  ],
			  apiVersion : 'v2',
			  success : function(result) {
			    defer.resolve(result);
			  },
			  failed : function(error){
			    defer.reject(error);
			  }
			});

			return defer;
		};

	tutorialFactory.getStatus().then(function(result) {
		_validity.contactNumber = result.data.confirmationStatus;
		_validity.profilePicture = result.data.profileStatus;
		_validity.addProject = result.data.projectStatus;
	});

	tutorialFactory.getQuotationStatus().then(function(res) {
		_quotation = res.data.data;
	});

	_transactions().promise.then(function(response){
		if(response.length){
			response.map(function(transaction, index){
				_pendingTransactions.push(index);
			})
		}
	});
		
	_banner('status=active&sort=priority&isMobile=true&accountType=vendor').promise.then(function(response){
		_banners = response.banners;
	});

	//CHECK EXPIRING
	vendormetaManager.promise.then(function(){

		_isPublished = vendormetaManager.isPublished();
		_isMonetized = vendormetaManager.isMonetized();

		if (!_isMonetized) {
			masterclassPromo.open().then(angular.noop(), function(){
				videoEssentialService.open().then(angular.noop(), function(reason){
					launchAppService.init();
				});
			});
		}else{
        	availablePlanService.trialPlan().promise.then(function(result){
	            _trialPackage = result[0];
	        });
		}


		vendormetaManager.activeMembership().promise.then(function(){
            _activeMembership = vendormetaManager.activeMembership().hasActiveMembership();
        })

		vendormetaManager.detail().promise.then(function(){

			_origin = vendormetaManager.detail().raw().countryOfOrigin.data.iso;

			$scope.$watch(function(){
				return vendormetaManager.detail().expiringCity()
			},function(newval){
				var _cities = newval.slice(0,3);
				if(newval.length > 3) _cities.push('more');
				if(newval.length > 1) _cities[_cities.length-1] = 'and '+_cities[_cities.length-1];

				_expiringCity = newval;
				_transformedExpiringCity = _cities.join(', ');
			})

			_allCities = vendormetaManager.detail().arrayOfCities();

			_instagramVendor = (_isPublished && !vendormetaManager.detail().isExpiring() || _isPublished && !vendormetaManager.raw().contactCredit == 0)

			_accountManager = vendormetaManager.detail().raw().accountManager;

			availablePlanService.prepaidEligible().promise.then(function(result){
				_isELigiblePrepaid = result;

				var mySwiper = new Swiper ('.swiper-container-dashboard', {            
					slidesPerView: 'auto',
					spaceBetween: 20,
					loop: true,
					centeredSlides: true,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					}
				});

				if(document.getElementsByClassName('swiper-container-dashboard').length) {
					setTimeout(function() {
						$('.swiper-container-dashboard').css('opacity', 1);
					}, 500)
				}

				if(document.getElementsByClassName('alert-active').length) {
					if(document.getElementsByClassName('subheader-savedraft2').length) {
						$('.subheader-savedraft2').css('top', '125px');
						$('.edit-project-wrapper').css('margin-top', '125px');
					}
				}

				if(document.getElementsByClassName('trial-info-banner').length) {
					$('#header2.alert-active').css('height', '200px');
					$('#subheader-container .alert-active').css('margin-top', '160px');

					if(document.getElementsByClassName('subheader-savedraft2').length) {
						$('.subheader-savedraft2').css('top', '200px');
						$('.edit-project-wrapper').css('margin-top', '200px');
					}
				}

				if(document.getElementsByClassName('plan-info-banner').length) {
					$('#header2.alert-active').css('height', '160px');
					$('#subheader-container .alert-active').css('margin-top', '120px');

					if(document.getElementsByClassName('subheader-savedraft2').length) {
						$('.subheader-savedraft2').css('top', '160px');
						$('.edit-project-wrapper').css('margin-top', '160px');
					}

					if(document.getElementsByClassName('trial-info-banner').length) {
						$('#header2.alert-active').css('height', '310px');
						$('#subheader-container .alert-active').css('margin-top', '270px');

						if(document.getElementsByClassName('subheader-savedraft2').length) {
							$('.subheader-savedraft2').css('top', '310px');
						$('.edit-project-wrapper').css('margin-top', '310px');
						}
					}
				}

				if(document.getElementsByClassName('pending-transaction-banner').length) {
					$('#header2.alert-active').css('height', '105px');
					$('#subheader-container .alert-active').css('margin-top', '65px');

					if(document.getElementsByClassName('credit-notification').length) {
						$('#header2.alert-active').css('height', '183px');
						$('#subheader-container .alert-active').css('margin-top', '145px');
					}

					if(document.getElementsByClassName('trial-info-banner').length) {
						$('#header2.alert-active').css('height', '255px');
						$('#subheader-container .alert-active').css('margin-top', '215px');
					}

					if(document.getElementsByClassName('subheader-savedraft2').length) {
						$('.subheader-savedraft2').css('top', '105px');
						$('.edit-project-wrapper').css('margin-top', '105px');

						if(document.getElementsByClassName('credit-notification').length) {
							$('.subheader-savedraft2').css('top', '183px');
							$('.edit-project-wrapper').css('margin-top', '183px');
						}

						if(document.getElementsByClassName('trial-info-banner').length) {
							$('.subheader-savedraft2').css('top', '255px');
							$('.edit-project-wrapper').css('margin-top', '255px');
						}
					}
				}
			});
		})
	})

	GeoLocationService.then(function(res2){
		_userLocation = res2;
	})

	var _isInstagramConnected = function(){
		var connected = (_oldInstagramConnected) ? true : false;
		if (_socmed.length) {
			angular.forEach(_socmed, function(soc){
				if (soc.name == 'instagram') {
					connected = true;
					if(soc.tokenStatus == 'expired') _isInstagramExpired = true;
					if(!soc.accessToken) _isInstagramReconnected = true;
				}else {
					connected = false;
				}
			});
			return connected;
		}else {
			_isInstagramExpired = true;
		}
		return connected;
	},
	_isShowInstagramConnect = function(){
		var cookieInstaBanner = cookieServices.getCookie('BS-InstagramConnect');
		var instagramConnect = cookieInstaBanner ? JSON.parse(cookieServices.getCookie('BS-InstagramConnect')) : false;

		if (instagramConnect && instagramConnect.session == cookieServices.getCookie('BSID')) {
			_showInstagramConnect = instagramConnect.show;
		}

		return _showInstagramConnect;
	},
	_openInstagramConnect = function(){
		var connected = _isInstagramConnected();
		var template = 'modal-instagram-reconnect.html';

		// if (!connected) {
		// 	show = true;
		// 	if (_oldInstagramConnected) {
		// 		template = 'modal-instagram-reconnect.html';
		// 	}
		// }

		var modalInstance = $modal.open({
			templateUrl : template,
			windowClass : 'popup',
			controller : 'instagrammConnectController'
		});

		modalInstance.result.then(function(args){
			if (location.pathname.search('/dashboard/edit') == -1) {
				window.location.href = redirect_url + '/dashboard/edit#/edit-profile#instagram';
			} else {
				$rootScope.$broadcast('scroll.profile', 'instagram');
			}
		});
	};

	var user = new resourceManager.skeleton({
		API : '/me?include=socmed,details',
		apiVersion : 'v2'
	});

	user.promise.then(function(res){
		var _showPopupReconnect = true;

		var _isCommencing = launchAppFactory.getCommenceState();

		var _instagramReconnect = (cookieServices.getCookie('BS-InstagramReConnect')) ? JSON.parse(cookieServices.getCookie('BS-InstagramReConnect')) : false;

		if(_instagramReconnect && (_instagramReconnect.session == cookieServices.getCookie('BSID'))){
			_showPopupReconnect = false;
		}

		_oldInstagram = res.vendor.details.data.instagram ? res.vendor.details.data.instagram : '';
		_oldInstagramName = _oldInstagram.split('instagram.com/');
		_oldInstagramConnected = _oldInstagramName.length > 1;
		_socmed = res.vendor.socmed ? res.vendor.socmed.data : [];

		if (_showPopupReconnect && (!_isInstagramConnected() && _isInstagramReconnected) && _instagramVendor) {
			_openInstagramConnect();
			var instagramPopUpReconnect = {session : cookieServices.getCookie('BSID')};
			cookieServices.setCustomCookie('BS-InstagramReConnect', instagramPopUpReconnect," 1 days");
		}
		
		_finishLoading = true;
	});

	return {
    	language : language,
    	isELigiblePrepaid : function(){
    		return _isELigiblePrepaid;
    	},
    	userLocation : function () {
    		return _userLocation;
    	},
    	isOrigin : function(){
    		return _origin;
    	},
		isValid : function(){
			return _validity;
		},
		pendingTransactions : function(){
			return _pendingTransactions;
		},
		isDone : function(){
			return _validity.contactNumber && _validity.profilePicture && _validity.addProject;
		},
		isPublished : function(){
			return _isPublished;
		},
		isFreePlan : function(){
			return _vendormeta().activeMembershipCount == 0;
		},
		vendormeta : function(){
			return _vendormeta();
		},
		zeroCredit : function(){
			return _vendormeta().contactCredit == 0
		},
		isMonetized : function(){
			return _isMonetized
		},
		accountManager : function(){
			return _accountManager;
		},
		instaBannerClosed : function(){
			return cookieServices.getCookie('BS-InstagramConnect');
		},
		transformedExpiringCity : function(){
			return _transformedExpiringCity;
		},
		isInstagramConnected: _isInstagramConnected,
		isInstagramExpired: function(){
			return _isInstagramExpired;
		},
		isInstagramReconnected: function(){
			return _isInstagramReconnected;
		},
		hideInstagramConnect: function(){
			var instagtamConnect = {session : cookieServices.getCookie('BSID'), show:false};
			cookieServices.setCustomCookie('BS-InstagramConnect', instagtamConnect," 1 days")
		},
		openInstagramConnect: _openInstagramConnect,
		scrollTo: ['element', function(element){
			$rootScope.$broadcast('scroll.profile', element);
		}],
		expiringCity : function(){
			return _expiringCity
		},
		lowestPrice : _lowestPrice,
		currency : _currency,
		isExpiring : function(){
			return vendormetaManager.detail() && vendormetaManager.detail().isExpiring();
		},
		goTo : function(url,condition){
			if(!condition) window.location = url;
		},
		instagramBanner : function(){
			if(_isShowInstagramConnect() && ((_isInstagramConnected() && (_isInstagramReconnected || _isInstagramExpired)) || !_isInstagramConnected()) && _instagramVendor)
				return true;
			else
				return false;
		},
		finishLoading: function(){
			return _finishLoading;
		},
		notifications: function(){
			if(notificationService.getMeta().newMessageCount != undefined) {
				return notificationService.getMeta();
			}else{
				return _vendormeta();
			}
		},
        trackVerifyContactNumber: function(){
            AnalyticService.eventTrack('Unpublished_Vendor_Dashboard', {'category' : 'business_site_action', 'label' : 'unpublished_dashboard_verify_contact'});
        },
        trackVerifyProfilePicture: function(){
            AnalyticService.eventTrack('Unpublished_Vendor_Dashboard', {'category' : 'business_site_action', 'label' : 'unpublished_dashboard_profile_picture'});
        },
        trackVerifyProjects: function(){
            AnalyticService.eventTrack('Unpublished_Vendor_Dashboard', {'category' : 'business_site_action', 'label' : 'unpublished_dashboard_add_projects'});
        },
        trialPackage : function(){
        	return _trialPackage;
        },
		activeMembership : function(){
			return _activeMembership;
		},
		goToCheckout : function(){
			var __data = {
				'cities' : [],
				'addOns' : []
			};
        	angular.forEach(_allCities, function(item, key){
				__data.cities.push({
					id : item.id,
					type : 'gold'
				})
			});
			return window.location.href = redirect_url+'/dashboard/checkout#/subscription-plan?data='+JSON.stringify(__data);
		},
		quotationStatus: function(){
			return _quotation;
		},
		closeQuotationBanner: function(){
			return localStorageService.set("quotationBannerClosed", true);
		},
		isBannerClosed: function(){
			return localStorageService.get("quotationBannerClosed");
		},
		goToBspay: function() {
			window.location.pathname = '/bspay';
		},
		banners: function () {
			return _banners;
		},
		zeroCreditAlert: function () {
			if(this.activeMembership() == 0 && this.zeroCredit() && !this.isMonetized()) return true;
			return false;
		},
		creditAlert: function() {
			if(this.isFreePlan() && (this.vendormeta().contactCredit > 0 && this.vendormeta().contactCredit < 6) && !this.isMonetized() && this.isPublished()) return true;
			return false;
		},
		creditAlert2: function() {
			if(this.isFreePlan() && (this.vendormeta().contactCredit > 5 && this.vendormeta().contactCredit < 19) && !this.isMonetized() && this.isPublished()) return true;
			return false;
		},
		planInfoBanner: function() {
			if(!this.isFreePlan() && this.isExpiring() && this.isMonetized() && !this.instagramBanner()) return true;
			return false;
		},
		showAlert: function () {
			if(this.zeroCreditAlert() || this.creditAlert() || this.creditAlert2() || this.pendingTransactions().length || this.planInfoBanner() || this.isMonetized()) return true;

			return false;
		}
	}
}])
.controller('subheaderDashboardController', ['vendormetaManager', 'tutorialFactory', function(vendormetaManager, tutorialFactory){
	var _isLoading = true,
		_origin = null,
		_currentPath = '',
		_totalPendingOrder = null,
		_kyc = {
			status: 'draft',
			showPopup: false
		};

	vendormetaManager.promise.then(function(){
		vendormetaManager.detail().promise.then(function(){

			_origin = vendormetaManager.detail().raw().countryOfOrigin.data.iso;
			
			_isLoading = false;

		})
	});

	tutorialFactory.getPendingOrder().then(function (res) {
		_totalPendingOrder = res.data.pagination.total_data
	})

	return {
		initPath : function(path) {
			_currentPath = path;
		},
		isLoading : function(){
			return _isLoading;
		},
		currentPathMatch : function(checker){
			return _currentPath.match(checker) !== null
		},
		vendor : vendormetaManager,
		totalPendingOrder : function() {
			return _totalPendingOrder;
		},
		isOrigin : function () {
			return _origin;
		},
		kyc: function() {
			return _kyc
		},
		payoutsEligible: function() {
			return tutorialFactory.getKYCDocuments().then(function (response) {
				if(response.data.status == 'approved') {
					window.location.pathname = '/bspay/payouts'
				} else {
					_kyc.showPopup = true;
					_kyc.status = response.data.status
				}
			})
		}
	}
}])
.controller('instagrammConnectController', ['$scope', '$modalInstance', '$http', 'InstagramService', 'notificationService', 'cookieServices', function($scope, $modalInstance, $http, InstagramService, notificationService, cookieServices){
	$scope.connect = function(){
		$modalInstance.close();
	};
}]);
