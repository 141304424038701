'use strict';

angular.module('bridestoryApp', [
	'ngRoute',
	'ipCookie',
	'bridestoryApp.resource',
	'LocalStorageModule',
	'bridestoryApp.cookiesApp',
	'bridestoryApp.newLoginApp',
	'bridestoryApp.menuApp',
	'bridestoryApp.pageCounterApp',
	'bridestoryApp.analyticApp',
	'bridestoryApp.autoCompleteApp',
	'bridestoryApp.notificationApp',
  	'bridestoryApp.vendorSearchApp',
  	'bridestoryApp.filters',
  	'bridestoryApp.decorative',
	])

.config(['$interpolateProvider', '$routeProvider', '$locationProvider', function($interpolateProvider, $routeProvider, $locationProvider) {
	$interpolateProvider.startSymbol('[[');
	$interpolateProvider.endSymbol(']]');
}])
.run(['AnalyticService', function (AnalyticService) {
	AnalyticService.pageTrack('GA');
}]);
