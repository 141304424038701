function tutorialFactory($http, cookieServices) {
	return {
		getStatus : function(){
			return $http({
				url : Bridestory.api + '/v1/account_status',
				method : 'GET',
				headers : {
		      'Session-Id': cookieServices.getCookie('BSID')
		    }
			}).then(function (response) {
				return response;
			})
		},
		getQuotationStatus : function(){
			return $http({
				url : Bridestory.api + '/' + Bridestory.quotation_path + '/me?include=charge_and_benefit',
				method : 'GET',
				headers : {
		      'Session-Id': cookieServices.getCookie('BSID')
		    }
			}).then(function (response) {
				return response;
			})
		},
		getPendingOrder: function () {
			return $http({
				url : Bridestory.api + '/ms/deals/api/v1/transactions/vendor?status=pending',
				method : 'GET',
				headers : {
					'Session-Id': cookieServices.getCookie('BSID')
				}
			}).then(function (response) {
				return response;
			})
		},
		getKYCDocuments: function () {
			return $http({
				url : Bridestory.api + '/ms/bs-document/api/v1/kyc_documents',
				method : 'GET',
				headers : {
					'Session-Id': cookieServices.getCookie('BSID')
				}
			}).then(function (response) {
				return response;
			})
		}
	}
}

angular
  .module('bridestoryApp.tutorialApp')
  .factory('tutorialFactory', ['$http', 'cookieServices', tutorialFactory]);